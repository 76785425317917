import { FormFields } from "@/components/ui/QuoteModal/QuoteModal";
import { isUndefined } from "@/utils";
import { z } from "zod";

const isDevMode = import.meta.env.MODE === "development";
const BASE_URL = isDevMode ? "http://localhost:8000" : "https://telluria.fluxwebdesign12.be";
const BASE_PATH = `${BASE_URL}/wp-json/flux-child/v1`;

export class APIError extends Error {
	static assert(condition: boolean, message?: string) {
		if (!condition) {
			message = isUndefined(message) ? `API Error` : `API Error: ${message}`;
			throw new APIError(message);
		}
	}
}

export const createFormFromObject = (object: Record<string, string | Blob>) => {
	const form = new FormData();

	for (const [key, value] of Object.entries(object)) {
		form.append(key, value);
	}

	return form;
};

const createResponseSchema = z.object({
	data: z.object({
		post_id: z.number(),
	}),
	success: z.literal<boolean>(true),
});

export const createQuotePost = async (data: FormFields, configuration?: object) => {
	const name = `${data.firstname} ${data.surname}`;
	const address =
		data.street && data.number && data.postalCode && data.country
			? `${data.street} ${data.number}\n${data.postalCode}, ${data.country}`
			: "";

	const body = createFormFromObject({
		contactnaam: name,
		email_klant: data.email,
		gsm_klant: data.phone,
		adres: address,
		quote_data: JSON.stringify(configuration ?? {}, null, 4),
	});

	const response = await fetch(`${BASE_PATH}/quotes`, { method: "POST", body });
	APIError.assert(response.ok, `Failed to create quote post: ${response.statusText}`);

	return createResponseSchema.parse(await response.json());
};

const updateResponseSchema = z.object({
	message: z.string(),
	post_id: z.string(),
	pdf_url: z.string(),
	pdf_url_2: z.string(),
	time_stamp: z.any(),
});

export const updateQuotePost = async (postId: string, pdf: string) => {
	const body = createFormFromObject({
		offer_id: postId,
		offer_pdf: pdf,
	});

	const response = await fetch(`${BASE_PATH}/update-quote`, { method: "POST", body });
	APIError.assert(response.ok, `Failed to update quote post: ${response.statusText}`);

	return updateResponseSchema.parse(await response.json());
};

import { isUndefined } from "@/utils";
import { ComponentProps } from "react";
import styled, { css } from "styled-components";

type StyleProps = {
	$active: boolean;
	$gap?: "small" | "medium" | "large";
	$padding?: "small" | "medium" | "large";
};

const CardContainer = styled.div<StyleProps>`
	width: 100%;
	display: flex;
	position: relative;
	gap: ${({ $gap, theme }) => (isUndefined($gap) ? 0 : theme.gap[$gap])};
	padding: ${({ $padding, theme }) => (isUndefined($padding) ? 0 : theme.padding.extra_large)};
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background-color: white;

	border: 1px solid transparent;
	transition: border-color 0.15s ease;

	&:hover {
		border-color: ${({ theme }) => theme.colors.light_grey};
	}

	${({ $active, theme }) =>
		$active &&
		css`
			border-color: ${theme.colors.primary};

			&::after {
				content: "";

				position: absolute;
				top: 0.5rem;
				right: 0.5rem;

				background-color: ${theme.colors.primary};
				border-radius: 50%;
				width: 0.875rem;
				height: 0.875rem;
			}
		`}
`;

export type CardProps = Omit<ComponentProps<typeof CardContainer>, "$active"> & { isActive?: boolean };
export default function Card({ children, isActive = false, ...props }: CardProps) {
	return (
		<CardContainer $active={isActive} {...props}>
			{children}
		</CardContainer>
	);
}

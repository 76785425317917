import Card from "@/components/primitives/Card";
import CONFIG, { ColorVariant } from "@/config";
import { useAppStore } from "@/stores/appStore";
import translations, { DEFAULT_LOCALE } from "@/translations";
import { capitalize } from "@/utils";
import { createFileRoute } from "@tanstack/react-router";
import styled from "styled-components";

const colors: ColorVariant[] = ["anthracite", "grey", "duo", "white"];

export const CardGrid = styled.div<{ $count: number }>`
	list-style: none;
	display: grid;
	gap: ${({ theme }) => theme.gap.small};
	grid-template-columns: repeat(${({ $count }) => $count}, 1fr);

	button {
		all: unset;
		width: 100%;

		&:hover {
			cursor: pointer;
		}
	}
`;

export const Route = createFileRoute("/_configurator/colors")({
	component: Colors,
});

function Colors() {
	const color = useAppStore((store) => store.configuration.color);
	const setColor = useAppStore((store) => store.setColor);

	return (
		<CardGrid $count={2}>
			{colors.map((name) => {
				const wallColor = name === "duo" ? "white" : (name as keyof typeof CONFIG.colors);
				const pillarColor = name === "white" ? "white" : "anthracite";

				return (
					<button key={name} title={capitalize(name)} onClick={() => setColor(name)}>
						<Card isActive={name === color} $padding="large">
							<ColorBadge $leftColor={wallColor} $rightColor={pillarColor} />
							<Card.Title>{translations[name][DEFAULT_LOCALE]}</Card.Title>
						</Card>
					</button>
				);
			})}
		</CardGrid>
	);
}

type ColorBadgeProps = {
	$leftColor: keyof typeof CONFIG.colors;
	$rightColor: keyof typeof CONFIG.colors;
};

const ColorBadge = styled.div<ColorBadgeProps>`
	border-radius: 50%;
	overflow: hidden;
	width: 4rem;
	height: 4rem;
	background-color: #${({ $leftColor }) => CONFIG.colors[$leftColor].getHexString()};

	&:after {
		content: "";
		display: inline-block;
		width: 2rem;
		height: 4rem;
		background-color: #${({ $rightColor }) => CONFIG.colors[$rightColor].getHexString()};
	}
`;

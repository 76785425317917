import Button from "@/components/primitives/Button";
import translations, { DEFAULT_LOCALE } from "@/translations";
import { zodResolver } from "@hookform/resolvers/zod";
import mergeRefs from "merge-refs";
import { forwardRef, MouseEventHandler, useCallback, useRef, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import styled from "styled-components";
import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import { createQuotePost, updateQuotePost } from "@/api";
import ExitButton from "./ExitButton";
import TextInput from "./TextInput";
import { APIError } from "@/api/quotes";
import useScreenshot from "@/hooks/useScreenshot";
import CONFIG from "@/config";
import usePrice from "@/hooks/usePrice";
import { useAppStore } from "@/stores/appStore";

const Dialog = styled.dialog`
	width: 90vw; // 90% of viewport width
	max-width: 600px; // Maximum width for larger screens
	padding: ${({ theme }) => theme.padding.extra_large};

	// Can't set this in global scope because it breaks dialog default functionality.
	&[open] {
		border: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	form {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.gap.large};
	}

	.inputs {
		display: flex;
		border: none;
		flex-direction: column;
		gap: ${({ theme }) => theme.gap.small};
	}

	.help {
		font-size: 0.75rem;
		color: ${({ theme }) => theme.colors.dark_grey};
	}

	.modal-header {
		display: flex;
		justify-content: space-between;
		margin-bottom: ${({ theme }) => theme.gap.large};
	}

	.form-header {
		font-size: 1.125rem;
		margin-bottom: ${({ theme }) => theme.gap.medium};
	}

	.error {
		color: ${({ theme }) => theme.colors.warning};
	}
`;

const schema = z.object({
	firstname: z.string().nonempty(translations.nameRequired[DEFAULT_LOCALE]),
	surname: z.string().nonempty(translations.surnameRequired[DEFAULT_LOCALE]),
	company: z.string().optional(),
	street: z.string().optional(),
	number: z.string().optional(),
	town: z.string().optional(),
	country: z.string().nonempty(translations.countryRequired[DEFAULT_LOCALE]),
	postalCode: z.string().optional(),
	phone: z.string().nonempty(translations.phoneRequired[DEFAULT_LOCALE]),
	email: z.string().email(translations.invalidEmail[DEFAULT_LOCALE]),
});

export type FormFields = z.infer<typeof schema>;

const QuoteModal = forwardRef<HTMLDialogElement>(function QuoteModal(_, ref) {
	const {
		register,
		handleSubmit,
		reset,
		setError,
		formState: { errors, isSubmitting, isSubmitSuccessful },
	} = useForm<FormFields>({ resolver: zodResolver(schema) });
	const localRef = useRef<HTMLDialogElement>(null);
	const config = useAppStore((store) => store.configuration);
	const getScreenshot = useScreenshot();
	const [url, setURL] = useState("");
	const { price } = usePrice();

	const getQuoteMutation = useMutation({
		mutationFn: (data: FormFields) => createQuotePost(data, config),
		onError: (error) =>
			error instanceof APIError && setError("root", { message: translations.somethingWentWrong[DEFAULT_LOCALE] }),
	});

	const updateQuoteMutation = useMutation({
		mutationFn: (data: { postId: number; pdf: string }) => updateQuotePost(String(data.postId), data.pdf),
		onError: (error) =>
			error instanceof APIError && setError("root", { message: translations.somethingWentWrong[DEFAULT_LOCALE] }),
	});

	const onSubmit = useCallback<SubmitHandler<FormFields>>(
		async (data) => {
			const getQuote = getQuoteMutation.mutateAsync;
			const updateQuote = updateQuoteMutation.mutateAsync;

			// Create the quote with the initial data and save the post id.
			const body = (await getQuote(data)).data;
			const { post_id: postId } = body;

			// Take a screenshot of the configuration.
			const image = await getScreenshot([
				{ position: [0, CONFIG.cameraHeight, CONFIG.cameraDistance], lookAt: [0, 0, 0] }, // FRONT
				{ position: [CONFIG.cameraDistance, CONFIG.cameraHeight, 0], lookAt: [0, 0, 0] }, // RIGHT
				{ position: [0, CONFIG.cameraHeight, -CONFIG.cameraDistance], lookAt: [0, 0, 0] }, // BACK
				{ position: [-CONFIG.cameraDistance, CONFIG.cameraHeight, 0], lookAt: [0, 0, 0] }, // LEFT
			]);

			// Sets <key> to <value> in PDF.
			const insertions = {
				Name: data.firstname,
				Surname: data.surname,
				Company: data.company,
				Street: data.street,
				StreetNr: data.number,
				Town: data.town,
				Country: data.country,
				PostalCode: data.postalCode,
				Phone: data.phone,
				Email: data.email,
				OrderNr: postId,
				SubTotal: price,
				Discount: "0",
				VAT: "0",
				Price: price,
				Notes: "",
				ScreenshotFront: image[0],
				ScreenshotRight: image[1],
				ScreenshotBack: image[2],
				ScreenshotLeft: image[3],
				Roof: config.roof,
				Color: config.color,
			};

			// Load the template PDF and insert the data.
			const createPDF = (await import("@/utils/pdf")).default;
			const pdf = await createPDF("telluria-offerte.pdf", insertions, false, []);

			// Update the quote with the generated PDF.
			const { pdf_url: pdfURL } = await updateQuote({ postId, pdf });
			setURL(pdfURL);
		},
		[config.color, config.roof, getQuoteMutation.mutateAsync, getScreenshot, price, updateQuoteMutation.mutateAsync]
	);

	const getTextInputProps = (key: keyof FormFields) => ({
		...register(key),
		error: errors[key],
		isRequired: !schema.shape[key].isOptional(),
		label: translations[key][DEFAULT_LOCALE],
	});

	const closeModal = useCallback<MouseEventHandler>(
		(event) => {
			event.preventDefault();

			if (isSubmitSuccessful) {
				reset();
			}

			localRef.current?.close();
		},
		[isSubmitSuccessful, reset]
	);

	return (
		<Dialog ref={mergeRefs(ref, localRef)}>
			<div className="modal-header">
				<h3>{translations.requestQuote[DEFAULT_LOCALE]}</h3>
				<ExitButton onClick={closeModal} />
			</div>

			<form onSubmit={handleSubmit(onSubmit)}>
				{isSubmitSuccessful ? (
					<>
						<h4 className="form-header">{translations.pdfSent[DEFAULT_LOCALE]}</h4>
						<a href={url} target="_blank" rel="noreferrer">
							{translations.downloadPDF[DEFAULT_LOCALE]}
						</a>
						<Button variant="primary" onClick={closeModal}>
							{translations.close[DEFAULT_LOCALE]}
						</Button>
					</>
				) : (
					<>
						<h4 className="form-header">{translations.contactDetails[DEFAULT_LOCALE]}</h4>
						<div className="inputs">
							<TextInput {...getTextInputProps("firstname")} />
							<TextInput {...getTextInputProps("surname")} />
							<TextInput {...getTextInputProps("street")} />
							<TextInput {...getTextInputProps("number")} />
							<TextInput {...getTextInputProps("postalCode")} />
							<TextInput {...getTextInputProps("town")} />
							<TextInput {...getTextInputProps("country")} />
							<TextInput {...getTextInputProps("phone")} />
							<TextInput {...getTextInputProps("email")} />

							<p className="help">{translations.requiredFieldsHelp[DEFAULT_LOCALE]}</p>
						</div>

						<Button variant={isSubmitting ? "busy" : "primary"} disabled={isSubmitting}>
							{translations.send[DEFAULT_LOCALE]}
						</Button>

						{errors.root && <p className="error">{errors.root.message}</p>}
					</>
				)}
			</form>
		</Dialog>
	);
});

export default QuoteModal;

import data from "./prices.json";
import { ColorVariant } from ".";
import { WallCode } from "./walls";

export type Prices = {
	color: Record<ColorVariant, number>;
	walls: Record<WallCode, number>;
};

const prices = data as Prices;
export default prices;

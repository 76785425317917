import { ComponentProps, ReactNode } from "react";
import styled from "styled-components";
import Steps from "./Steps";
import usePrice from "@/hooks/usePrice";

const Price = styled.p`
	color: ${({ theme }) => theme.colors.black};
	background-color: ${({ theme }) => theme.colors.background_light};
	font-size: 1.25rem;
	font-weight: 300;
	padding: 1rem;
	text-align: center;
	margin-top: auto;
	padding: ${({ theme }) => theme.padding.medium};
`;

type SidepanelProps = {
	children: ReactNode;
} & ComponentProps<typeof SidepanelContainer>;

export default function Sidepanel({ children, ...props }: SidepanelProps) {
	const { price } = usePrice();
	return (
		<SidepanelContainer {...props}>
			<Steps />
			<SidepanelContent>{children}</SidepanelContent>
			<Price>€ {price.toFixed(2)}</Price>
		</SidepanelContainer>
	);
}

const SidepanelContainer = styled.div`
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.white};
`;

const SidepanelContent = styled.div`
	width: 100%;
	padding: ${({ theme }) => theme.padding.large};
	overflow-y: auto;
`;

import CONFIG from "@/config";
import prices from "@/config/prices";
import { useAppStore } from "@/stores/appStore";
import { Configuration } from "@/stores/appStore/store";
import { isUndefined } from "@/utils";
import { useCallback } from "react";

export default function usePrice() {
	const configuration = useAppStore((store) => store.configuration);

	const getPrice = useCallback((configuration: Configuration) => {
		const roofPrice = CONFIG.roofs.byId[configuration.roof].price;
		const wallPrices = Object.values(configuration.walls).reduce(
			(total, wall) => (isUndefined(wall) ? total : total + prices.walls[wall]),
			0
		);
		const colorPrice = prices.color[configuration.color];

		return roofPrice + wallPrices + colorPrice;
	}, []);

	const comparePriceToCurrent = useCallback(
		(change: Partial<Configuration>) => {
			const newConfiguration: Configuration = { ...configuration, ...change };
			return getPrice(newConfiguration) - getPrice(configuration);
		},
		[configuration, getPrice]
	);

	return {
		price: getPrice(configuration),
		getPrice,
		comparePriceToCurrent,
	};
}

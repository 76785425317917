import { StateCreator } from "zustand";
import CONFIG, { ColorVariant, RoofCode } from "@/config";
import { WallConfiguration, WallCode, WallLocation } from "@/config/walls";
import CameraControls from "camera-controls";
import { RootState } from "@react-three/fiber";

const isDevMode = import.meta.env.DEV;

export type Configuration = {
	roof: RoofCode;
	walls: WallConfiguration;
	color: ColorVariant;
};

const defaultConfiguration: Configuration = {
	roof: CONFIG.roofs.byId["00"].id,
	walls: {
		front: undefined,
		left: undefined,
		right: undefined,
		back: undefined,
	},
	color: "anthracite",
};

export type State = {
	configuration: Configuration;
	selectedWall?: WallLocation;
	showGrid: boolean;
	showWalls: boolean;
	showMeasurements: boolean;
	isDevMode: boolean;
	controls?: CameraControls;
	three?: RootState;
};

export type Actions = {
	setRoof: (roofCode: RoofCode) => void;
	toggleSelectedWall: (location: WallLocation) => void;
	setWallByLocation: (location: WallLocation, wall: WallCode) => void;
	setWallBySelected: (wall: WallCode) => void;
	setColor: (color: ColorVariant) => void;
	update: (options: Partial<State>) => void;
	reset: () => void;
};

const store: StateCreator<State & Actions> = (set) => ({
	configuration: defaultConfiguration,
	isDevMode,
	showGrid: true,
	showWalls: true,
	showMeasurements: false,
	selectedWall: "front",
	setRoof: (roofCode) => {
		set((state) => ({
			...state,
			configuration: { ...defaultConfiguration, roof: roofCode },
		}));
	},
	toggleSelectedWall: (location) =>
		set((state) => ({
			...state,
			selectedWall: state.selectedWall !== location ? location : undefined,
		})),
	setWallByLocation: (location, wall) =>
		set((state) => ({
			...state,
			configuration: { ...state.configuration, walls: { ...state.configuration.walls, [location]: wall } },
		})),
	setWallBySelected: (wall) =>
		set((state) =>
			state.selectedWall
				? {
						...state,
						configuration: {
							...state.configuration,
							walls: { ...state.configuration.walls, [state.selectedWall]: wall },
						},
					}
				: state
		),
	setColor: (color) => set((state) => ({ ...state, configuration: { ...state.configuration, color } })),
	update: (options) => set((state) => ({ ...state, ...options })),
	reset: () =>
		set((state) => ({ ...state, configuration: { ...defaultConfiguration, roof: state.configuration.roof } })),
});

export default store;

import data from "./walls.json";

export type Size = "XS" | "S" | "M" | "L" | "XL";
type SizeMap = Record<Size, number>;

type Width = "16" | "23";
type Segment = "FF" | "FA" | "FAW" | "DL" | "DR" | "DD" | "SW" | "ST" | "DT";
export type SegmentCode = `${Width}${Segment}`;

export type WallLocation = "left" | "right" | "front" | "back";
export type WallCode = keyof typeof data;
export type WallConfiguration = Record<WallLocation, WallCode | undefined>;

export type Wall = {
	id: WallCode;
	size: Size;
	segments: [SegmentCode] | [SegmentCode, SegmentCode];
	needsDivider: boolean;
	iconURL: string;
};

export const wallSizes: Record<"outer" | "inner", SizeMap> = {
	outer: {
		XS: 1.6,
		S: 2.3,
		M: 3.2,
		L: 3.9,
		XL: 4.6,
	},
	inner: {
		XS: 1.305,
		S: 2.055,
		M: 2.875,
		L: 3.625,
		XL: 4.375,
	},
};

const byId = data as Record<WallCode, Wall>;
const walls = { byId };

export default walls;

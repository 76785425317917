import { useAppStore } from "@/stores/appStore";
import { isNotNullOrUndefined } from "@/utils";
import { RootState } from "@react-three/fiber";
import { useCallback, useRef } from "react";
import { PerspectiveCamera, RGBAFormat, SRGBColorSpace, TypedArray, WebGLRenderer, WebGLRenderTarget } from "three";

const _useStableRef = <T>(create: () => T): T => {
	const ref = useRef<T>();

	if (!ref.current) {
		ref.current = create();
	}

	return ref.current;
};

const flipBuffer = (buffer: TypedArray, width: number, height: number) => {
	const flippedBuffer = new Uint8Array(buffer.length);
	const rowBytes = width * 4;

	for (let y = 0; y < height; y++) {
		const row = height - 1 - y;
		flippedBuffer.set(buffer.subarray(row * rowBytes, (row + 1) * rowBytes), y * rowBytes);
	}

	return flippedBuffer;
};

const canvasToBlobAsync = (canvas: HTMLCanvasElement, type: string) =>
	new Promise<Blob>((resolve, reject) => {
		const callback = (blob: Blob | null) =>
			isNotNullOrUndefined(blob) ? resolve(blob) : reject(new Error("Unable to save screenshot"));
		canvas.toBlob(callback, type);
	});

const saveBufferAsImage = (pixels: TypedArray, width: number, height: number, type: string) => {
	// Create a canvas to extract the image from.
	const canvas = document.createElement("canvas");
	canvas.width = width;
	canvas.height = height;

	// Load the image into the canvas.
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const context = canvas.getContext("2d")!;
	const imageData = context.createImageData(width, height);

	imageData.data.set(pixels);
	context.putImageData(imageData, 0, 0);

	// Convert the image to a blob.
	return canvasToBlobAsync(canvas, type);
};

const getPixelsFromRenderTarget = async (gl: WebGLRenderer, target: WebGLRenderTarget) => {
	const { width, height } = target;
	const buffer = new Uint8Array(width * height * 4);

	// readRenderTargetPixels reads the pixels bottom to top, so they need to be flipped.
	const flippedPixels = await gl.readRenderTargetPixelsAsync(target, 0, 0, width, height, buffer);
	const pixels = flipBuffer(flippedPixels, width, height);

	return pixels;
};

const setupCamera = (option: Option) => {
	const camera = new PerspectiveCamera(45);

	camera.position.set(...option.position);
	camera.lookAt(...option.lookAt);

	return camera;
};

const getScreenShot = async (three: RootState, option: Option) => {
	const width = 800;
	const height = 600;
	const camera = setupCamera(option);
	const renderTarget = new WebGLRenderTarget(width, height, {
		format: RGBAFormat,
		colorSpace: SRGBColorSpace,
	});

	// Set the render target, render,
	// then revert back to the canvas by setting the target to null.
	three.gl.setRenderTarget(renderTarget);
	three.gl.render(three.scene, camera);
	three.gl.setRenderTarget(null);

	const pixels = await getPixelsFromRenderTarget(three.gl, renderTarget);
	const image = await saveBufferAsImage(pixels, width, height, "image/png");

	return image;
};

type Option = {
	position: [number, number, number];
	lookAt: [number, number, number];
};

type ScreenshotFn = {
	(options: Option[]): Promise<Blob[]>;
	(options: Option): Promise<Blob>;
};

const useScreenshot = () => {
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	const three = useAppStore((store) => store.three)!;

	return useCallback(
		(async (options: Option | Option[]) => {
			if (Array.isArray(options)) {
				const results: Blob[] = [];

				for (const option of options) {
					const screenshot = await getScreenShot(three, option);
					results.push(screenshot);
				}

				return results;
			} else {
				return getScreenShot(three, options);
			}
		}) as ScreenshotFn,
		[three]
	);
};

export default useScreenshot;

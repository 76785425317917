import { selectors, useAppStore } from "@/stores/appStore";
import translations, { DEFAULT_LOCALE } from "@/translations";
import { createFileRoute } from "@tanstack/react-router";
import { WallLocation } from "@/config/walls";
import { MouseEvent, useCallback } from "react";
import { isUndefined, startAnimation } from "@/utils";
import CONFIG from "@/config";
import Radio from "@/components/primitives/Radio";
import { CardGrid } from "./colors";
import Card from "@/components/primitives/Card";
import styled from "styled-components";

export const Route = createFileRoute("/_configurator/walls")({
	component: Walls,
});

const options: WallLocation[] = ["front", "left", "right", "back"];

function Wall({ location }: { location: WallLocation }) {
	const controls = useAppStore((store) => store.controls);
	const selectedWall = useAppStore((store) => store.selectedWall);
	const toggleSelectedWall = useAppStore((store) => store.toggleSelectedWall);
	const isSelected = selectedWall === location;

	const focusCamera = useCallback(
		(location: WallLocation) => {
			const position = {
				front: { x: 0, y: CONFIG.cameraHeight, z: CONFIG.cameraDistance },
				left: { x: -CONFIG.cameraDistance, y: CONFIG.cameraHeight, z: 0 },
				right: { x: CONFIG.cameraDistance, y: CONFIG.cameraHeight, z: 0 },
				back: { x: 0, y: CONFIG.cameraHeight, z: -CONFIG.cameraDistance },
			}[location];

			startAnimation(() => {
				if (!isUndefined(controls)) {
					void controls.setPosition(position.x, position.y, position.z, true);
				}
			});
		},
		[controls]
	);

	const onLabelClickHandler = useCallback(
		(event: MouseEvent) => {
			event.preventDefault();
			toggleSelectedWall(location);
			focusCamera(location);
		},
		[toggleSelectedWall, location, focusCamera]
	);

	return (
		<Radio.Item $isActive={isSelected} onClick={onLabelClickHandler}>
			{translations[location][DEFAULT_LOCALE]}
		</Radio.Item>
	);
}

function WallSelect() {
	const setWallBySelected = useAppStore((store) => store.setWallBySelected);
	const options = useAppStore(selectors.getAvailableWallsBySelected);
	const selectedWall = useAppStore(selectors.getWallBySelected);

	return (
		<CardGrid $count={3}>
			{options.map((option) => {
				return (
					<button key={option.id} onClick={() => setWallBySelected(option.id)}>
						<Card isActive={selectedWall?.id === option.id} $padding="large">
							<Card.Image src={option.iconURL} $fit="contain" />
						</Card>
					</button>
				);
			})}
		</CardGrid>
	);
}

function Tabs() {
	return (
		<Radio>
			{options.map((option) => (
				<Wall key={option} location={option} />
			))}
		</Radio>
	);
}

const Divider = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${({ theme }) => theme.colors.light_grey};
`;

const Container = styled.div`
	display: flex;
	gap: 1rem;
	flex-direction: column;
	align-items: center;
`;

function Walls() {
	return (
		<Container>
			<Tabs />
			<Divider />
			<WallSelect />
		</Container>
	);
}
